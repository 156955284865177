export interface GreenWithHouseProps {
  page: string;
}

export interface ButtonProps {
  action: 'GetStarted' | 'Buying' | 'Refinance';
}

export const getLink = (action: ButtonProps['action']) => {
  switch (action) {
    case 'GetStarted':
      return 'get-started';
    case 'Buying':
      return 'buying';
    case 'Refinance':
      return 'refinance';
  }
};

export const getButtonText = (action: ButtonProps['action']) => {
  switch (action) {
    case 'GetStarted':
      return 'Get Started';
    default:
      return action;
  }
};
