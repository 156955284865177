import {
  createOwnUpComponent,
  createOwnUpStyle,
  fonts,
  OwnUpBox,
  OwnUpText
} from '@rategravity/own-up-component-library';
import React, { Fragment } from 'react';
import { useMixpanel } from '../../hooks/use-mixpanel';
import { GreenBannerButton } from '../button';
import { UnderlinedLinkInternal } from '../links';
import { PrimaryText } from '../typography';
import { ButtonProps, getButtonText, getLink, GreenWithHouseProps } from './helpers';
export * from './helpers';

const HeaderTextStyle = createOwnUpStyle({
  ...fonts.TIEMPOS_HEADLINE_MEDIUM,
  fontFamily: "'TiemposHeadline-Medium', 'Palatino', 'Times New Roman', 'serif'",
  display: 'block',
  fontSize: 42,
  letterSpacing: 0,
  marginBottom: 30,
  textAlign: 'center',
  variants: {
    smallAndDown: {
      fontSize: 32
    }
  }
});

const HeaderText = createOwnUpComponent(OwnUpText, HeaderTextStyle);

const ContentStyle = createOwnUpStyle({
  maxWidth: 960,
  padding: 75,
  margin: 'auto',
  variants: {
    smallAndDown: {
      padding: '64px 16px'
    }
  }
});

export const Content = createOwnUpComponent(OwnUpBox, ContentStyle);

const CallToActionStyle = createOwnUpStyle({
  margin: '20px auto 0px auto',
  variants: {
    smallAndDown: {
      margin: '20px 0px'
    }
  }
});

export const CallToAction = createOwnUpComponent(OwnUpBox, CallToActionStyle);

const AnchoredButton = ({ action }: ButtonProps) => {
  const mixpanel = useMixpanel();

  const mixpanelLocation = (action: string) => {
    switch (action) {
      case 'Buying':
        return '3R-ankle-buying';
      case 'Refinance':
        return '3R-ankle-refi';
      default:
        return 'ankle-get-started';
    }
  };

  return (
    <a
      href={
        action === 'Refinance'
          ? `${process.env.GATSBY_ONBOARDING}/${getLink(action)}`
          : `${process.env.GATSBY_DISCOVER}/${getLink(action)}`
      }
      style={{
        textDecoration: 'none'
      }}
      aria-label={`${getButtonText(action)} with Own Up!`}
      onClick={() =>
        mixpanel.track('Click discover CTA', {
          'page-location': mixpanelLocation(action)
        })
      }
    >
      <GreenBannerButton>{getButtonText(action).toUpperCase()}</GreenBannerButton>
    </a>
  );
};

const ThreeReasonsButtonWrapperStyle = createOwnUpStyle({
  flexGrow: 'unset',
  paddingLeft: 10,
  paddingRight: 10,
  marginBottom: 20
});

const ThreeReasonsWrapper = createOwnUpComponent(OwnUpBox, ThreeReasonsButtonWrapperStyle);

const ThreeReasonsCTA = () => (
  <Fragment>
    <HeaderText>We created Own Up to get you the best deal on your mortgage.</HeaderText>
    <OwnUpBox
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: -20
      }}
    >
      <ThreeReasonsWrapper>
        <AnchoredButton action="Buying" />
      </ThreeReasonsWrapper>
      <ThreeReasonsWrapper>
        <AnchoredButton action="Refinance" />
      </ThreeReasonsWrapper>
    </OwnUpBox>
  </Fragment>
);

const GenericCTA = () => (
  <Fragment>
    <HeaderText>
      It pays to shop for the right loan. Whether buying or refinancing, our customers save an
      average of $27k over the life of their loan.
    </HeaderText>
    <PrimaryText style={{ textAlign: 'center', paddingBottom: 30 }}>
      <UnderlinedLinkInternal to="/methodology/">
        See how the savings are calculated.
      </UnderlinedLinkInternal>
    </PrimaryText>
    <OwnUpBox style={{ display: 'flex', justifyContent: 'center' }}>
      <AnchoredButton action="GetStarted" />
    </OwnUpBox>
  </Fragment>
);

export const CallToActionContent = ({ page }: GreenWithHouseProps) =>
  page.match(/3-reasons/g) ? <ThreeReasonsCTA /> : <GenericCTA />;
