import {
  createOwnUpComponent,
  createOwnUpStyle,
  OwnUpBox
} from '@rategravity/own-up-component-library';
import React from 'react';
import { metadata } from '../../data/metadata/images';
import HouseOutsideTreesSVG from '../../images/house-outside-trees.svg';
import { colors } from '../../modules/colors';
import { CallToAction, CallToActionContent, Content, GreenWithHouseProps } from './components';

const WrapperStyle = createOwnUpStyle({
  width: '100%',
  backgroundColor: colors.MINT
});

const Wrapper = createOwnUpComponent('aside', WrapperStyle);

export const GreenWithHouse = ({ page }: GreenWithHouseProps) => (
  <Wrapper>
    <Content>
      <OwnUpBox
        style={{
          margin: 'auto',
          maxWidth: 210
        }}
      >
        <img alt={metadata['house-outside-trees.svg'].alt} src={HouseOutsideTreesSVG} />
      </OwnUpBox>
      <CallToAction>
        <CallToActionContent page={page} />
      </CallToAction>
    </Content>
  </Wrapper>
);
